//Colors
	$b: #000;
	$w: #fff;
	$red: #f50537;
	$blue:#1c4570;
	$lblue:#2f6297;
	$darkgrey:#1a1a1a;
	$lgrey: #cfcfcf;
	$lightgrey:#f2f2f2;
	$midgrey:#333;
	$grey: #b3b3b3;

	$success:#090;
	$error:#bb0a30;

	$clear:transparent;
//Font sizes

//Percentages
	$p1: 100%;
	$p2: 50%;
	$p3: 33.33333%;
	$p4: 25%;
	$p5: 20%;
	$p8: 12.5%;
	$p10: 10%;
//Easing
	$ease: cubic-bezier(0.750, 0.020, 0.500, 1.000);
	$easeAlt: cubic-bezier(0.85, 0, 1, 1);
	$easeInQuart:cubic-bezier(0.5, 0, 0.75, 0);
	$easeOutQuart:cubic-bezier(0.25, 1, 0.5, 1);
	$easeInOutQuart:cubic-bezier(0.76, 0, 0.24, 1);
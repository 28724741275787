@import 'fonts';
@import 'prefix';
@import 'variables';
@import 'form';
@import 'card';

* {
	box-sizing:border-box;
}
:root {
	--space-xxl:36px;
	--space-xxxl:42px;
	--pad-side:4%;
	--fixed-side:4%;
	@include tablet {
		--space-xxxl:48px;		
	}
	@include mid {
		--space-xxl:42px;
		--space-xxxl:60px;	
	}
	@include desktop {
		--space-xxl:54px;
		--space-xxxl:78px;	
		--pad-side:12%;	
	}
	@include max {
		--space-xxl:66px;
		--space-xxxl:96px;
	}
}

html {
	width:$p1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin:0;
	background:$darkgrey;
	body {
		@extend html;
		font-family: $fontFamily;
		font-weight:400;
	}
}

img {
	display:block;
}
h1,h2,h3,h4,h5,h6,p {
	font-family:$fontFamily!important;
	&.center {
		text-align: left;
		@include tablet {
			text-align: center;			
		}
	}
	&.white {
		color:$w;
	}
}
h1 {
	&.thin-line {
		line-height:1.2;
	}
}
button,a {
	&.cta,&.primary {
		background-color:$red!important;
		border-color:$red!important;
	}
	&.secondary {
		border-color:$w!important;
		color:$w!important;
		&.inverse {
			border-color:$b!important;
			color:$b!important;
		}
	}
}
button.primary,button.secondary,a.primary,a.secondary {
	min-width:240px!important;
	-webkit-font-smoothing:auto!important;
	span {
		-webkit-font-smoothing:auto!important;
	}
}
button.cta {
	-webkit-font-smoothing:auto!important;
	span {
		-webkit-font-smoothing:auto!important;
	}
}

.hidden {
	display:none!important;
}





.container {
	width:$p1;
	max-width: 1920px;
	min-height: 100vh;
	margin:0 auto;
	&.thanks {
		@include transition(filter 1s $ease);
		&:after {
			content:'';
			position: fixed;
			top:0;
			left:0;
			width:$p1;
			height:$p1;
			z-index:99999;
			background:rgba(0,0,0,0);
			opacity:0;
			visibility: hidden;
			@include transition(background 1s $ease,opacity 1s $ease,visibility 1s $ease);
		}
	}
}
.content {
	background:$darkgrey;
	color:$w!important;
	h1,h2,h3,h4,h5,h6,p {
		color:inherit;
	}
}

.video-intro,.video-outro {
	padding-top:0;
	@include tablet {
		padding-bottom:0;		
	}
	.video-holder {
		width:$p1;
		height:100vh;
		height: calc(var(--vh, 1vh) * 100);
		overflow: hidden;
		@include flex;
		justify-content: center;
		align-items:center;
		flex-direction: column;
		video {
			display:block;
			width:$p1;
			height:$p1;
			object-fit:cover;
			object-position: center top;
		}
		.center-play {
			cursor: pointer;
			z-index:9;
			@include flex;
			flex-direction: column;
			align-items:center;
			justify-content: flex-end;
			position: absolute;
			top:0;
			left:0;
			width:$p1;
			height:$p1;
			background:rgba(0,0,0,.5);
			@include transition(background .3s $ease);
			&.playing {
				background:$clear;
				.play-video {
					opacity:0;
					visibility: hidden;
				}
			}
			.play-actions {
				position: absolute;
				top:0;
				left:0;
				width:$p1;
				height:$p1;
				z-index:0;
			}
			.play-video {
				@include flex;
				background:$clear;
				height:$p1;
				align-items:center;
				cursor: pointer;
				@include transition(opacity .3s $ease,visibility .3s $ease);
				@include tablet {
					height: $p2;			
					align-items: flex-end;
					svg {
						&:hover {
							@include transform(scale(1.1));			
						}
					}
				}
			}
			@include tablet {
				&:after {
					content:'';
					position: absolute;
					bottom:0;
					left:0;
					width:$p1;
					height:$p1;
					z-index:-1;
					background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
					background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
					background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
					opacity:1;
					@include transition(opacity .4s $ease);
				}			
			}
		}
		.intro {
			cursor: default;
			justify-content: flex-end;
			opacity: 1;
			@include transition(opacity .4s $ease);
			h1 {
				color:$w;
			}
			p {
				color:$w;
			}
		}
		@media (max-width:768px){
			height:400px;
		}
		@include tablet {
			max-height: 530px;
			@media (max-height:800px){
				padding-bottom:24px;
				h1 {
					margin-bottom:0;
				}
				p {
					margin:10px 0;
				}
			}
			.words {
				br {
					display:none;
				}
			}
		}
		@include mid {
			min-height:700px;	
			max-height:720px;
			.words {
				br {
					display:block;
				}
			}
		}
		@include desktop {
			min-height:700px;	
			max-height:980px;		
		}
	}
	&.hide-intro {
		.intro {
			opacity:0;
		}
		.center-play {
			@include tablet {
				&:after {
					opacity:0;
				}
			}
		}
	}
}
.video-outro {
	padding-top:24px;
	@include tablet {
		padding-bottom:0;		
	}
}

.module {
	padding:var(--space-xxxl) var(--pad-side);
	// margin:0 0 80px;
	position: relative;
	img {
		width:$p1;
	}
	&.intro {
		width:$p1;
		padding-left:4%;
		padding-right:4%;
		@include flex;
		flex-direction: column;
		align-items: flex-start;
		@include tablet {		
			align-items: center;
		}
		&:not(.space){
			padding-top:24px;
		}
		h1 {
			@include tablet {
				width:76%;		
			}
			@media (max-width:540px){
				font-size:5vw!important;
				line-height:1.4;
			}
		}
		p {
			@include tablet {
				// width:60%;
			}
			@media (max-width:540px){
				margin-top:0;
			}
		}
		.button-group {
			flex-grow:0;
			flex-wrap:wrap;
			justify-content: flex-start;
			@include tablet {
				justify-content: center;		
			}
			> div {
				@media (max-width:768px){
					margin-inline-start:0!important;
					button {
						margin-left:0;
						margin-right:0;
					}
				}
				@media (max-width:540px){
					width:$p1;
					margin-left:0!important;
					margin-right:0!important;
					& + a,& + button {
						margin-top:6px;
					}
				}
			}
		}
		button {
			&.social-btn {
				position: relative;
				overflow: hidden;
				span.social-text {
					display:inline-block;
					@include transform(translateY(0));
					opacity:1;
					@include transition(transform .3s $ease,opacity .3s $ease);
				}
				.social-btns {
					display:none;
					position: absolute;
					top:0;
					left:0;
					width:$p1;
					height:$p1;
					background:$red;
					@include flex;
					justify-content: center;
					align-items:center;
					@include transform(translateY($p1));
					@include transition(transform .3s $ease,opacity .3s $ease);
					opacity:0;
					direction:ltr;
					a {
						color:$w;
						svg {
							width: 22px;
							height: 22px;
							display:block;
						}
						& + a {
							margin-left:12px;
						}
					}
				}
				&:hover {
					// span.social-text {
					// 	opacity:0;
					// 	@include transform(translateY(-$p1));
					// }
					// .social-btns {
					// 	@include transform(translateY(0));
					// 	opacity:1;
					// }
				}
			}
		}
	}
	&.banner {
		width:$p1;
		position: relative;
		padding:0;
		overflow: hidden;
		height:auto;
		text-align: center;
		background: $b;
		@include tablet {
			height:calc(100vh - 72px);		
		}
		@include desktop {
			height:calc(100vh - 96px);				
		}
		.bg {
			width:$p1;
			@include tablet {
				height:$p1;		
			}
			img {
				object-fit:cover;
				width:$p1;
				height:$p1;
			}
		}
		.banner-content {
			position:relative;
			left:0;
			width:$p1;
			padding:var(--space-xxl) var(--fixed-side);
			@include flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-end;
			// bg
			background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
			@include tablet {
				align-items: center;	
				position: absolute;
				bottom:0;
				height:$p2;	
			}
			h1 {
				text-align: left;
				@include tablet {
					text-align: center;		
				}
				@media (max-width:540px){
					font-size:5vw!important;
					line-height:1.4;
				}
			}
			h4 {
				text-align: left;
				@include tablet {
					text-align: center;		
				}
			}
			.button-group {
				flex-grow:0;
				flex-wrap:wrap;
				justify-content: center;
				button,a {
					margin:0;
					&.secondary {
						display:none;
						@include tablet {
							display:block;		
						}
					}
				}
				> div {
					@media (max-width:768px){
						margin-right:0!important;
						margin-left:0!important;
					}
				}
			}
		}
		video {
			position: absolute;
			top:$p2;
			left:$p2;
			width:auto;
			height:auto;
			min-width:$p1;
			min-height:$p1;
			@include transform(translate(-$p2,-$p2));
		}
	}
	&.form {
		padding-top:var(--space-xxl);
	}
	&.map {
		padding:0;
		display:grid;
		grid-template-columns: 5fr 3fr;
		.map-loader {
			height:$p1;
		}
		.map-wrapper {
			height:800px;
		}
		.map-view {
			height:$p1;
		}
		.info {
			height:$p1;
			.inner {
				width:$p1;
				height:$p1;
				background:$midgrey;
				padding:var(--space-xxl) var(--space-xxl);	
			}
		}
	}
}

.dual-teaser {
	padding:var(--space-xxxl) var(--pad-side) 0;
	position: relative;
	&:before {
		content:'';
		position: absolute;
		top:0;
		left:$p2;
		width:0;
		height:2px;
		background:$red;
		@include transform(translateX(-$p2));
		@include transition(width .8s 0s $ease);
	}
	&.inview {
		&:before {
			width:calc(100% - var(--pad-side)*2);
		}
	}
	&.video1 {
		&:before {
			display:none;
		}
	}
	@include tablet {
		padding:var(--space-xxxl) var(--pad-side);		
	}
	.dual-reverse {
		flex-direction: column-reverse!important;
		@include tablet {
			flex-direction:row!important;
		}
	}
	.text-col {

	}
	h1 {
		position: relative;
		padding-bottom:10px;
		@media (max-width:540px){
			font-size:5vw!important;
			line-height:1.4;
		}
		.words {
			&:after {
				content:'';
				position: absolute;
				bottom:0;
				left:0;
				width:0;
				height:4px;
				background:$red;
				@include transition(width .4s 1s $ease);
			}
			&.in {
				&:after {
					width:$p1;
				}
			}
		}
	}
}
.video-holder {
	position: relative;
	overflow: hidden;
	video {
		cursor: pointer;
		display:block;
		width:$p1;
		height:auto;
	}
	.play-video:not(.in-banner) {
		position: absolute;
		z-index:2;
		top:0;
		left:0;
		width:$p1;
		height:$p1;
		background:rgba(0,0,0,.5);
		@include flex;
		align-items:center;
		justify-content: center;
		cursor: pointer;
		@include transition(background .3s $ease,opacity .3s $ease,visibility .3s $ease);
		&:hover {
			background:rgba(0,0,0,.6);
			svg {
				@include tablet {
					@include transform(scale(1.1));			
				}
			}
		}
	}
	.play-video {
		svg {
			background:$red;
			border-radius: $p1;
			padding:6px; 
			color:white;
			fill:white;
			width:60px;
			height:60px;
			@include transition(transform .3s $ease);
			@include tablet {
				width:80px;
				height:80px;
			}
		}
		&.playing {
			opacity:0;
			visibility: hidden;
		}
	}
}


// bar
.bar {
	background:$red;
	&.fixed {
		position: relative;
		width:$p1;
		bottom:0;
		z-index:9;
		background:$darkgrey;
		@include transition(background .4s $ease);
		select {
			background:$darkgrey;
		}
		&.active {
			background:$red;
			select {
				background:$red;
			}
		}
		&:hover {
			@include tablet {
				background:$red;		
				select {
					background:$red;
				}
			}
		}
		.bar-inner {
			padding:22px 6% 28px; 
			@include tablet {
				padding:28px var(--pad-side);
			}
		}
		&.done {
			display:none;
		}
	}
	.bar-inner {
		padding:16px 10px;
		text-align: center;
		@include tablet {
			padding:20px var(--pad-side);	
		}
		@include desktop {
			padding:32px var(--pad-side);
		}
		form {
			max-width:1080px;
			margin:0 auto;
			text-align: left;
			.question-field {
				input,label {
					color:$w!important;
				}
			}
			#submit-btn {
				margin-top:0;
				background:$clear;
				border-color:$clear;
				color:$w;
				@include tablet {
					color:$b;
					border-color:$w;
					background:$w;		
				}
			}
		}
		h2,h3,h4 {
			color:$w;
			font-size:3.5vw !important;		
			@include tablet {
				font-size:16px!important;		
			}
			@include mid {
				font-size:20px!important;	
			}
		}
		p {
			color:$w;
		}
	}
}

// footer
footer {
	background:$b;
	padding:26px 4% 24px;
	margin-top:0px;
	@include mid {
		padding:30px 4% 24px;	
	}
	.top-footer {
		padding-bottom:20px;
		border-bottom:1px solid #4c4c4c;
		margin-top:0;
		flex-direction:column!important;
		@include mid {
			margin-top:24px;
			flex-direction:row!important;	
		}
	}
	.bot-footer {
		flex-direction:column!important;
		@include mid {
			flex-direction:row!important;	
		}
	}
	a {
		color:$w;
		text-decoration: none;
		font-weight:bold;
		&:hover {
			color:#b3b3b3;
			p {
				color:#b3b3b3;
			}
		}
	}
	.footer-links {
		@include flex;
		flex-direction:column;
		margin-bottom:26px;
		@include mid {
			flex-direction:row;	
			margin-bottom:0;
		}
		a {
			cursor: pointer;
			& + a {
				margin-top:10px;
				@include mid {
					margin-top:0;
					margin-left:20px;	
				}
			}
		}
	}
	.social-links {
		a {
			svg {
				width: 22px;
				height: 22px;
			}
			& + a {
				margin-left:20px;
			}
		}
	}
	.language-switch {
		margin-top:10px;
		display: block;
		@include mid {
			margin-top:0;	
		}
	}
}





// plugins


// lettering
.words {
	span.ch {
		display:inline-block;
		opacity:0;
		will-change: opacity,transform;
		@include transform(translateY($p1));
	}
	&.down {
		span.ch {
			opacity:0;
			@include transform(translateY($p1));
		}
	}
	&.up {
		span.ch {
			opacity:0;
			@include transform(translateY(-$p1));
			@include transition(opacity .1s $ease,transform .1s $ease);
		}
	}
	&.in {
		span.ch {
			opacity:1;
			@include transform(translateY(0));
			@include transition(opacity .1s $ease,transform .1s $ease);
		}
	}
	br {
		display:none;
		@include tablet {
			display:block;		
		}
	}
}





// checkout
.checkout-container {
	@include tablet {
		margin-bottom:60px;		
	}
}
.checkout-summary {
	margin:auto;
	margin-top:32px;
	width:92%;
	img {
		margin:auto;
		max-width:720px;
		width:92%;
	}
	.intro {
		a {
			color:$w!important;
		}
		@media(max-width:768px){
			padding-bottom:16px;
		}
	}
}
.summary-header {
	width:$p1;
	max-width:890px;
	margin:auto;
	background:$red;
	padding:14px 24px;
	@include tablet {
		width:92%;		
		border-radius:10px 10px 0 0;
	}
	.prices {
		text-align: right;
		h2 {
			font-size:16px;
		}
	}
	h2 {
		color:$w;
	}
	@media (max-width:1024px){
		flex-direction: column!important;
		align-items: flex-start!important;
		padding:10px 12px;
		h2 {
			line-height:1.4;
			font-size:14px;
			& + h2 {
				margin-top:6px;
			}
		}
	}
}
.payment-content {
	background:$lightgrey;
	width:$p1;
	max-width:890px;
	margin:auto;
	padding:14px 16px 32px;
	@include tablet {
		width:92%;
		padding:22px 24px 42px;		
		border-radius:0 0 10px 10px;
	}
}
.summary-content {
	margin-bottom:32px;
	border-bottom:1px solid rgba(0,0,0,.2);
	padding:0px 0px 16px;
	@include tablet {
		padding:10px 10px 24px;		
	}
	img {
		width:$p1;
		margin-top:0px;
		position: relative;
		z-index:0;
	}
	h2 {
		position: relative;
		z-index:2;
	}
}
.card-content {
	@media (max-width:1024px){
		flex-wrap:wrap;
		.card-holder {
			margin-bottom:24px;
		}
		.card-holder,.card-form {
			flex-basis:$p1;
			margin-inline-start:0;
			margin-inline-end:0;
		}
		.card-holder > div {
			justify-content: center;
		}
		.card-form form {
			margin:auto;
		}
		.rccs {
			width:300px;
			.rccs__card {
				width:300px;
				height:192px;
			}
		}
	}
	@media(max-width:500px){
		.rccs {
			width:92vw;
			.rccs__card {
				width:$p1;
				height:56vw;
			}
		}
	}
}
.card-holder {
	@include flex;
}
.checkout-form {
	width:$p1;
	max-width:400px;
	@include tablet {
		width:92%;
	}
	@include mid {
		width:$p1;
	}
	.last-row {
		// margin-top:40px;
	}
	legend {
		margin-block-end:0;
	}
	.date-divider {
		margin-top:5px;
		color:$b;
	}
	input#expiry_month,
	input#expiry_year {
		width:44px;
	}
	#checkout-btn {
		background:$red;
		border-color:$red;
		color:$w;
		&[disabled] {
			background:$lgrey;
			border-color:$lgrey;
			color:$darkgrey;
		}
		&.processing {
			background:$red;
			border-color:$red;
			color:$w;
			padding-block-start: 12px;
			padding-block-end: 12px;
		}
		&.white {
			background:$w;
			border-color:$w;
			color:$b;
			&[disabled] {
				background:$lgrey;
				border-color:$lgrey;
				color:$darkgrey;
			}
			&.processing {
				background:$w;
				border-color:$w;
				color:$b;
			}
		}
		> div {
			position:relative;
			margin-right:5px;
			width:0!important;
			@include transition(width .4s ease);
		}
		span {
			margin-inline-start:0!important;
			@include transition(margin .4s ease);
		}
		&.processing {
			> div {
				width:32px!important;
				height:32px!important;
			}
			span {
				margin-inline-start:12px!important;
			}
		}
	}
	.global-error {
		p {
			color:$red;
		}
	}
}
.checkout-fine {
	max-width:800px;
	margin:auto;
}


.email-checker {
	width:$p1;
	margin:24px auto;
	max-width:400px;
}





.selection {
	@include flex;
	justify-content: center;
	align-items:center;
	height:$p1;
	width:100vw;
	.region-select,.model-select {
		padding:24px 28px;
		margin:10% 0 5%;
		width:$p1;
		max-width:1000px;
		nav {
			@include flex;
			flex-wrap:wrap;
			button {
				margin-right:20px;
				margin-bottom:10px;
			}
		}
	}
	.model-select {
		margin-top:5%;
	}
}



.popup-content {
	margin: auto;
	background: rgb(255, 255, 255);
	width: 100%;
	@include tablet {
		width: 80%;	
	}
	padding: 5px;
}
.popup-arrow {
	color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
	width: 200px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
	background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
	background: transparent;
}

@keyframes anvil {
	0% {
		transform: scale(1) translateY(0px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	1% {
		transform: scale(0.96) translateY(10px);
		opacity: 0;
		box-shadow: 0 0 0 rgba(241, 241, 241, 0);
	}
	100% {
		transform: scale(1) translateY(0px);
		opacity: 1;
		box-shadow: 0 0 500px rgba(241, 241, 241, 0);
	}
}
.popup-content {
	-webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.modal {
	overflow-x:hidden;
	overflow-y: scroll;
    height: 90vh;
	padding:4%;
	.close {
	    position: absolute;
	    right: 0;
	    top: 0;
        margin: 2px 16px;
		font-size: 40px;
	    cursor: pointer;
	    @include tablet {
	    	margin: 12px 36px;
	    	font-size: 50px;		
	    }
	}
	ol {
		margin:32px 0;
	}
}


.form-btns {
	@include flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	@include tablet {
		flex-direction: row;	
		align-items:center;	
	}
}


// arabic
[dir="rtl"]{
	.module{
		&.banner {
			.banner-content {
				h1 {
					text-align: right;
					@include tablet {
						text-align: center;		
					}
				}
				h4 {
					text-align: right;
					@include tablet {
						text-align: center;		
					}
				}
			}
		}
		&.banner,&.intro,&.dual-teaser {
			h1 {
				@media (max-width:540px){
					font-size:6.5vw!important;
				}
			}
		}
	}
	h1,h2,h3,h4,h5,h6,p {
		&.center {
			text-align: right;
			@include tablet {
				text-align: center;			
			}
		}
	}
	input#code {
		direction: ltr;
	}
	.bar {
		.bar-inner {
			form {
				.question-field {
					label {
						text-align: right;
					}
				}
			}
		}
	}
	footer {
		.footer-links {
			a + a {
				@include mid {
					margin-left:0;
					margin-right:20px;
				}
			}
		}
		.social-links {
			direction:ltr;
		}
	}


	.selection {
		.region-select,.model-select {
			nav {
				button {
					margin-left:20px;
					margin-right:0;
				}
			}
		}
	}

	#already-btn {
		margin-left:0!important;
		margin-right:0;
		@include tablet {
			margin-right:10px;	
		}
	}
	.tnc-content {
		text-align: right;
	}
}









@font-face {
	font-weight: 400 700;
	font-family: 'AudiType';
	font-style: normal;
	font-stretch: 100% 130%;
	src: url('fonts/AudiType.woff2') format('woff2'),url('fonts/AudiType.woff') format('woff');
  	font-display: swap;
}
@font-face {
	font-weight: 400 700;
	font-family: 'AudiType';
	font-style: normal;
	font-stretch: 100% 130%;
	src: url('fonts/AudiType.woff2') format('woff2'),url('fonts/AudiType.woff') format('woff');
  	font-display: swap;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/AM.woff2) format('woff2'),
    url(fonts/AM.woff) format('woff'),
    url(fonts/AM.ttf) format('ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/AB.woff2) format('woff2'),
    url(fonts/AB.woff) format('woff'),
    url(fonts/AB.ttf) format('ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/AB.woff2) format('woff2'),
    url(fonts/AB.woff) format('woff'),
    url(fonts/AB.ttf) format('ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'AudiAR';
    src: url(fonts/AB.woff2) format('woff2'),
    url(fonts/AB.woff) format('woff'),
    url(fonts/AB.ttf) format('ttf');
    font-weight: 900;
}

$fontFamily:AudiType, AudiAR, Verdana, Tahoma, sans-serif;
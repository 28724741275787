.grecaptcha-badge {
	display:none;
}

input, textarea, select, button {
	font-family: $fontFamily;
}
input,select,button {
	outline:0!important;
}
input,select {
	// @include tablet {
	// 	padding-right:0!important;
	// 	padding-left:0!important;			
	// }
}

// form
.form {
	background:$darkgrey;
	.inner {
		margin:0 auto;
		max-width:1080px;
	}
	form {
		width:$p1;	
		min-width:unset;
		padding-bottom:40px;
		.form-group {
			width:$p1;
			> div {
				width:$p1;
			}
			label {
				color:$w!important;
			}
		}
		#code-group {
			margin-top:-1px;
			#code {
				@include mid {
					min-width:65px;		
				}
			}
		}
		#phone__invalid-message {
			display:none;
		}
		#mobile-phone__error-message,
		#email__invalid-message {
			// position: absolute;
			// @include mid {
				position:relative;
				color:$red!important;		
			// }
		}
		#model,#city {
			text-transform:capitalize;
		}
		select {
			background:$darkgrey;
		}
	}
	&.fade-it {
		.fade {
			opacity:0;
			@include transform(translateY(15%));
		}
	}
}

// thanks
#thanks {
	padding:0 0 60px;
	.thanks-wrap {
		width:60%;
		margin:0 auto;
		@include transition(height 1s $ease);
		@include mid {
			width:92%;	
		}
		> div {
			@include mid {
				align-items:flex-start;	
			}
		}
		h2,p {
			text-align: center;
			@include mid {
				text-align: left;	
			}
		}
	}
	.reveal {
		opacity:0;
		@include transform(translateY(50%));
		@include transition(opacity .6s $ease,transform .6s $ease);
	}
	&.reveal-it {
		.reveal {
			opacity:1;
			@include transform(translateY(0));
			&.reveal1 {
				transition-delay:0s;
			} 
			&.reveal2 {
				transition-delay:.2s;
			} 
			&.reveal3 {
				transition-delay:.3s;
			} 
		}
	}
}

// consent
#consent {
	label {
		font-size:12px!important;
	}
}

#fineprint {
	font-size: 10px;
	line-height: 1.6;
}


#form-submit {
	@include mid {
		margin-inline-start:0;	
		width:220px;	
	}
}	
#already-btn {
	margin-top:14px;
	margin-left:0;
	@include tablet {
		margin-top:0;
		margin-left:10px;	
	}
	@include mid {
		margin-top:20px;
	}
}
// loader
#submit-btn {
	background:$red;
	border-color:$red;
	color:$w;
	@include mid {
		margin-top:20px;
		margin-left:0;	
	}
	&[disabled] {
		background:$lgrey;
		border-color:$lgrey;
		color:$darkgrey;
	}
	&.processing {
		background:$red;
		border-color:$red;
		color:$w;
		padding-block-start: 12px;
		padding-block-end: 12px;
	}
	&.white {
		background:$w;
		border-color:$w;
		color:$b;
		&[disabled] {
			background:$lgrey;
			border-color:$lgrey;
			color:$darkgrey;
		}
		&.processing {
			background:$w;
			border-color:$w;
			color:$b;
		}
	}
	> div {
		position:relative;
		margin-right:5px;
		width:0!important;
		@include transition(width .4s ease);
	}
	span {
		margin-inline-start:0!important;
		@include transition(margin .4s ease);
	}
	&.processing {
		> div {
			width:32px!important;
			height:32px!important;
		}
		span {
			margin-inline-start:12px!important;
		}
	}
}
.progress-loader{
	position: absolute;
	height: 32px;
	width: 32px;
	display: inline-block;
	animation: none;
	top: $p2;
    left: $p2;
    @include transform(translate(-$p2,-$p2));
    svg {
    	@include transform(rotate(0deg) scaleX(1));
    	&.stop-animation {
    		animation:none!important;
    	}
    }
    .circle-start {
    	stroke-dasharray: 227;
	  	stroke-dashoffset: 227;
		@include transition(stroke-dashoffset .7s ease);
    }
    .circle {
    	stroke-dasharray: 227;
	  	stroke-dashoffset: 227;
		@include transition(stroke-dashoffset .7s .4s ease);
    }
    .circle-end {
    	stroke-dasharray: 227;
	  	stroke-dashoffset: 227;
	  	@include transform(rotate(200deg));
   		transform-origin: center;
    }
	.check-icon{
		&:after{
		    position: absolute;
		    content: "";
		    top: $p2;
		    left: $p2;
		    @include transform(rotate(45deg));
		    height: 7px;
		    width: 7px;
		    margin: -5px 0 0 -6px;
		    border-top: 2px solid $w;
		    border-right: 2px solid $w;
		    @include transition(transform .2s ease,opacity .2s ease);
		    @include transform(scale(.8) rotate(135deg));
			opacity:0;
		}
	}
	&.process {
	    @include transform(translate(-$p2,-$p2));
	    svg {
	    	animation: rotate 2s .5s ease infinite forwards;
	    }
	    .circle-start {
	    	stroke-dasharray: 227;
		  	stroke-dashoffset: 0;
			@include transition(stroke-dashoffset .7s ease);
	    }
	    .circle {
	    	stroke-dashoffset: 160;
	    }
	    .check-icon{
	    	&:after{
				@include transform(scale(.8) rotate(135deg));
				opacity:0;
			}
		}
	}
    &.done {
	    .circle {
	    	stroke-dashoffset: 210;
	    }
		.circle-end {
			animation: centerCircle .5s .7s linear forwards;
		}
		.check-icon{
			&:after{
		        height: 0;
			    width: 0;
			    border-width:0;
			    margin: 0px 0 0 -8px;
			    @include transform(scaleX(-1) rotate(135deg));
			    transform-origin: left top;
			    @include transition(none);
			    animation: check-icon 1.4s .8s forwards ease;
			}
		}
	}
}

@keyframes rotate {
	100%{
		@include transform(rotate(360deg) scaleX(1));
	}
}
@keyframes check-icon {
	0%{
		border-width:2px;
		height: 0;
		width: 0;
		opacity: 1;
	}
	20%{
		border-width:2px;
		height: 0;
		width: 6px;
		opacity: 1;
	}
	40%{
		border-width:2px;
		height: 12px;
		width: 6px;
		opacity: 1;
	}
	100%{
		border-width:2px;
		height: 12px;
		width: 6px;
		opacity: 1;
	}
}

@keyframes centerCircle{
	0%{
		stroke-dasharray: 0 227;
		stroke-dashoffset: 114;
	}
	100%{
		stroke-dasharray: 227 0;
		stroke-dashoffset: 227;
	}
}













// arabic

[dir="rtl"]{
	* {
		font-family:AudiType,AudiAR,Verdana,Tahoma,sans-serif!important;
	}

	.form {
		.code-group {
			flex-direction: row-reverse;
		}
	}
	#submit-btn {
		> div {
			margin-right:0;
			margin-left:5px;
		}
	}
	.thanks-wrap {
		> div {
			@include mid {
				align-items:flex-end;	
			}
		}
		h2 {
			font-weight:900;
		}
		h2,p {
			text-align: center;
			@include mid {
				text-align: right;	
			}
		}
	}
}
